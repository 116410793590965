@import '../../assets/css/fonts';
@import '../../assets/css/mixins';

#dungeon-master-view-button {
    cursor: pointer;
    opacity: 0.5;
    position: absolute;
    right: 30px;
    top: 25px;
    transition: 0.5s;

    &:hover {
        opacity: 1;
    }
}
