@import '../../assets/css/fonts';
@import '../../assets/css/mixins';

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
}

.modal {
    @include font-body;

    background-color: #faf7ef;
    color: #111;
    height: 225px;
    margin: 0 auto;
    outline-offset: -14px;
    outline: 2px solid #d0b47b;
    overflow: none;
    padding: 50px;
    transform: translate(0, 50vh) translate(0, -65%);
    width: 400px;

    button {
        @include button;
        @include font-body-bold;

        background-color: transparent;
        font-size: 16px;
        margin: 0 10px;

        &:hover {
            border-bottom: 2px solid #111;
        }
    }

    .modal-buttons {
        margin-top: 20px;
        text-align: center;
    }

    .modal-body p:first-child {
        margin-top: 0;
    }

    .modal-content {
        i {
            @include font-body-italic;
        }

        em {
            @include font-body-bold-italic;
        }
    }

    .modal-heading {
        background-image: url(../../assets/images/blood.png);
        background-position: 150px 0;
        background-repeat: no-repeat;
        color: #d31818;
        font-family: HerrVonMuellerhoff;
        font-size: 60px;
        line-height: 80px;
        margin-bottom: 10px;
    }
}
