button.lock {
    background-color: #eeece1;
    border: 1px solid #e0e1d2;
    border-radius: 3px;
    color: #58170d;
    cursor: pointer;
    display: inline-block;
    font-family: monospace;
    font-size: 7px;
    font-variant: normal;
    font-weight: bold;
    letter-spacing: 0;
    margin-left: 7px;
    padding: 4px 6px 3px 5px;
    text-transform: uppercase;

    &.locked {
        background-color: #58170d;
        border: none;
        color: #eeece1;
    }
}
